<template>
  <template v-if="data?.intro">
    <div
        class="py-5.5 px-6 lg:px-7.5 rounded-20px bg-white cursor-pointer h-max"
        style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
        @click="isOpen = !isOpen"
    >
      <div class="flex justify-between gap-2.5">
        <p class="text-bw-11 font-semibold leading-5 xl:leading-7 text-15px xl:text-lg line-clamp-4">
          {{ data?.title }}
        </p>
        <span
            class="flex-none w-4 h-4 xl:w-5.5 xl:h-5.5 i-custom-chevron-down text-bw-11 transform duration-400"
            :class="isOpen ? '-rotate-180' : ' '"
        ></span>
      </div>
      <div class="item-parent" :class="isOpen ? 'activeList mt-3 xl:mt-5' : ''">
        <div v-html="data?.intro" class="text-sm xl:text-base text-bw-11/80 xl:leading-6 line-clamp-4"></div>
      </div>
    </div>
  </template>
  <template v-else>
    <nuxt-link
        :to="data?.url"
        class="py-5.5 px-6 lg:px-7.5 rounded-20px bg-white cursor-pointer h-max"
        style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
    >
      <div class="flex justify-between gap-2.5">
        <p class="text-bw-11 font-semibold leading-5 xl:leading-7 text-15px xl:text-lg line-clamp-3">
          {{ data?.title }}
        </p>
        <span class="w-4 h-4 xl:w-5.5 xl:h-5.5"></span>
      </div>
    </nuxt-link>
  </template>
</template>

<script setup lang="ts">
const isOpen = ref<boolean>(false)

const props = defineProps({
  data: {
    type: Object as any
  },
  index: {
    type: Number
  }
})

onMounted(() => {
  if (props.index == 1) {
    isOpen.value = true
  }
})
</script>

<style></style>
